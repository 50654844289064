<template>

  <div class="nav">
    <router-link to="/">
      <svg xmlns="http://www.w3.org/2000/svg" class="arrow" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"/>
      </svg>
    </router-link>
  </div>
  <div class="container">

    <div class="contact-container">
      <a href="mailto:victor.hodobay@gmail.com">
        <svg class="envelope" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
             stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
        </svg>
      </a><span>contact me</span>
    </div>

    <p>Or say a bit about your business inquiry below and I'll reach out as soon as possible.</p>

    <form v-on:submit.prevent="submitHandler" autocomplete="off">

      <div class="input-container">
        <label for="name">your name: </label>
        <input type="text"
               id="name"
               v-model.trim="name"
               @blur="validator"
               :class="{invalid: invalid.name}"
               @focus="invalid.name=false">
      </div>
      <br>
      <div class="input-container">
        <label for="name">e-mail: </label>
        <input type="email"
               id="email"
               v-model.trim="email"
               @blur="validator"
               :class="{invalid: invalid.email}"
               @focus="invalid.email=false"
        >
      </div>
      <br>
      <div class="input-container">
        <label for="message">message: </label>
        <input type="text"
               id="message"
               v-model.trim="message"
               :class="{invalid: invalid.message}"
               @focus="invalid.message=false"
               @blur="validator">
        <custom-button-light v-on:click.prevent="submitHandler">send</custom-button-light>
      </div>
    </form>
    <span v-show="warning" class="warning">{{ warning }}</span>
  </div>


</template>

<script>

import CustomButtonLight from "@/components/ui/customButtonLight";
import axios from 'axios'


export default {
  name: "contactMePage",
  components: {CustomButtonLight},
  computed: {
    validSubmitData() {
      return !(this.invalid.name || this.invalid.email || this.invalid.message || this.warning);
    },
  },
  methods: {
    async submitHandler() {
      this.submitValidator()
      if (!this.validSubmitData) return
      try {
        const submitData = {
          name: this.name,
          email: this.email,
          text: this.message,
        }
        const {data} = await axios.post('https://portfolio-express-vic.herokuapp.com/message', submitData)
        if (data) {
          this.name = ''
          this.email = ''
          this.message = ''
          this.warning = 'sent'
          window.localStorage.clear();
          setTimeout(() => {
            this.warning = null
          }, 5000)
        }
      } catch (e) {
        console.log(e)
      }
    },
    validator(e) {

      if (e.target.id === 'name') {
        if (e.target.value.length < 2) {
          this.invalid.name = true
        }else {

          this.saveToLocalStorage(e.target)
        }
      } else if (e.target.id === 'email') {
        if (e.target.value === ''
            || !e.target.value
            || e.target.value.length < 2
            || !e.target.value.includes('@')
            || !e.target.value.includes('.')) {
          this.invalid.email = true
        }else {

          this.saveToLocalStorage(e.target)
        }
      } else if (e.target.id === 'message') {
        if (e.target.value.length < 2) {
          this.invalid.message = true
        }else {

          this.saveToLocalStorage(e.target)
        }
      }
    },
    submitValidator() {
      if (this.invalid.name || !this.name) {
        this.warning = 'enter your name'
      } else if (this.invalid.email || !this.email) {
        this.warning = "enter a correct e-mail address"
      } else if (this.invalid.message || !this.message) {
        this.warning = "enter some message"
      } else {
        this.warning = null
      }
    },
    saveToLocalStorage(payload) {
      window.localStorage.setItem(payload.id, payload.value)
    }
  },
  data() {
    return {
      name: '',
      email: '',
      message: '',
      invalid: {
        name: false,
        email: false,
        message: false
      },
      warning: null,
    }
  },
  mounted() {
    const nameFromLocalStorage = window.localStorage.getItem('name')
    const emailFromLocalStorage = window.localStorage.getItem('email')
    const messageFromLocalStorage = window.localStorage.getItem('message')

    if (nameFromLocalStorage) {
      this.name=nameFromLocalStorage
    }
    if (emailFromLocalStorage) {
      this.email=emailFromLocalStorage
    }
    if (messageFromLocalStorage) {
      this.message=messageFromLocalStorage
    }

  }
}
</script>

<style scoped lang="scss">

.nav {
  position: fixed;
  z-index: 1;
  top: 1rem;
  left: 1rem;
  color: whitesmoke;
}

.container {
  position: relative;
  width: 100%;
  height: 100vh;
  background: center no-repeat url("../assets/images/PNG/Asset 34@2x.png"), var(--background-primary-dark);
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  color: whitesmoke;
  flex-direction: column;

  @media screen and (min-width: 40em) {
    background: center no-repeat url("../assets/images/PNG/Asset 28@2x.png"), var(--background-primary-dark);

  }


  form {
    font-family: "Futura PT", sans-serif;
    font-weight: normal;
    font-size: 2rem;
    @media screen and (min-width: 40em) {
      font-size: 3rem;
    }
    //your name....
    label {
      display: block;
      font-family: reross-quadratic, sans-serif;
    }

    //input
    input {

      border: none;
      margin: 0 1rem;
      border-radius: 0.4rem;
      width: 67vw;
      height: 2rem;
      border-bottom: solid .2rem whitesmoke;
      @media screen and (min-width: 40em) {
        width: 25vw;
        margin: 0 2rem;

      }

    }
  }
}

.invalid {
  border: solid .2rem crimson;
  background-color: lightpink;
}

.valid {
  border: solid .1rem #2d8f51;
  background-color: white;
}

.contact-container {

  height: auto;
  width: 100%;
  color: whitesmoke;
  font-family: reross-quadratic, sans-serif;
  font-size: 6rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media screen and (min-width: 40em) {
    font-size: 10rem;
  }

  a {
    position: relative;
    top: 1.7rem;
    @media screen and (min-width: 40em) {
      top: 3.7rem;
    }
  }
}

p {
  padding: 2rem 3rem;
}

.envelope {

  height: 6rem;
  width: auto;
  color: whitesmoke;
  @media screen and (min-width: 40em) {

    height: 8rem;
    width: auto;
  }
}

.arrow {

  height: 4rem;
  width: auto;
  color: whitesmoke;
  @media screen and (min-width: 40em) {

  }

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    transform: scale(.9);
  }
}

.warning {
  background-color: rgba(252, 252, 252, .2);
  font-family: reross-quadratic, sans-serif;
  font-weight: bolder;
  font-size: 3rem;
  padding: 1rem 3rem;
  position: relative;
  right: 3rem;
  border-radius: 0 0 5rem 0;
  box-shadow: var(--shadow-small);
}
</style>

<template>
  <button type="button">
    <slot>Button</slot>
  </button>
</template>

<script>
export default {
  name: "customButtonLight"
}
</script>

<style scoped lang="scss">
button {
  font-family: "Futura PT",sans-serif;
  background: rgba(252,252,252,.1);
  box-shadow: inset 1px 1px 1px 3px rgba(252,252,252,.3);
  border: .2rem solid whitesmoke;
  padding: 1.6rem;
  color: white;
  border-radius: .3rem;
  transition: all .3s ease;
  font-size: large;

  &:hover {
    box-shadow: var(--shadow-small);
    transform: scale(1.02);
  }

  &:active {
    box-shadow: inset 1px 1px 1px 7px rgba(0, 0, 0, .4);
    transform: scale(.99);
  }
}

</style>
